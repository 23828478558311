.panelNode__webhook_list_wrapper {
  margin-top: 10px;
  .node__webhook_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .node_webhook_add_btn {
    cursor: pointer;
    display: flex;
    padding: 0px;
    border: none;
    background-color: none !important;
    background : none !important;
  }
  .panelNode__webhook_list {
    margin-top: 10px;
  }
}
