.script__settings_card {
  .script__settings_tabs {
    margin-bottom: 10px;
  }

  .tab-content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }
  div[role='tabpanel'].active {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    .script_settings_css_textarea {
      flex: 1 1 auto;
      resize: none;
    }
  }
  .suitecrm__form_title {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #495057;
  }
  .suitecrm__form_inner {
    &_row {
      margin-top: 10px;
      display: flex;
      gap: 15px;
      width: 100%;
      align-items: center;
      div {
        flex: 1 1 33%;
      }
    }
  }
}
