@use 'assets/scss/variables.scss' as variables;

.footer {
  width: 100%;
  padding: 12px calc(24px / 2);
  color: variables.$footer-color;
  height: 50px;
  //position: absolute;
  background-color: variables.$footer-bg;
  &__text {
    text-align: end;
    white-space: nowrap;
  }
}


