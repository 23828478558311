.toggle {
    position: absolute;
    color: #a3a8b1;
    right: 2%;
    background-color: #fff;
    padding: 0 0.2rem;
    font-size: 24px;
    cursor: pointer;

    &:hover{
      font-weight: bold;
    }
 }


