@use 'assets/scss/variables.scss' as variables;

$node-button-delete-background: #74788d;
.edge {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(variables.$base_color, 0.2);
  transition: border 0.7s ease;


  &__button {
    padding: 0;
    position: absolute;
    right: 12px;
    bottom: 10px;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    pointer-events: all;
    
    &_remove {
      background: $node-button-delete-background;
    }
  }
}


.selected .edge {
  border: 5px solid #8ac79f;
}


