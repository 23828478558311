.statusOptions {
  width: 100%;
  &__content {
    padding-top: 5px;
    display: flex;
    justify-content: space-around;
  
  }
  &__label {
    padding-right: 0.5rem;
    display: flex;

    span {
      border-radius: 5px;
      padding: 0 5px;
      text-align: center;
      font-size: 12px;
      padding-bottom: 3px;
      margin-left: 3px;
    }

    &_positive {
      span {
        background: #34c38f4f;
        color: #309470;
      }
    }
    &_neutral {
      span {
        background: #eee56b59;
        color: #bb995e;
      }
    }

    &_negative {
      span {
        background: #f46a6a2d;
        color: #cf6464;
      }
    }
  }
}
