.users {
  ul.nav {
    margin: 1.25rem 1.25rem 0 1.25rem;
  }
  .tab-content {
    height: 100%;

    > .active {
      display: flex;
      height: 100%;
    }
  }

  &__card {
    height: 100%;
    display: flex;
  }
}
