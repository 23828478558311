.panelSearchScripts {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding-bottom: 1.2rem;

  &__search {
    display: flex;
  }

  &__select {
    width: 15rem;
    margin-right: 1rem;
  
  }
}
