@use 'assets/scss/variables.scss' as variables;

.modalCreateScript {
  padding: 0 1rem;
  display: flex;
  height: 20rem;
  flex-direction: column;
  justify-content: space-around;

  &__title {
    font-weight: bold;
  }

  &__content {
    display: grid;
    row-gap: 1rem;
  }

  &__textArea_noResize {
    resize: none;
    height: 5rem;

    &:focus {
      border-color: variables.$base_color;
      box-shadow: 0 0 0 0.25rem rgba(variables.$base_color, 0.2);
    }
  }

  &__button_submit {
    position: relative;
    width: 30%;
    left: 35%;
  }
}
