@use 'assets/scss/variables.scss' as variables;

.select__field_btn {
  padding: 5px 16px;

  background: #ffffff;
  border: 1px solid #d9d9d9;
  transition: border-color 0.2s linear, box-shadow 0.2s linear;
  border-radius: 4px;
  &.added {
    border-color: variables.$base_color;
  }
  &:hover {
    box-shadow: 0px 0px 6px variables.$base_color;
    border-color: variables.$base_color;
  }
}
