.alert__conteiner {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 76px;
  padding: 0 1.25rem;
  .btn-close {
    top: 35%;
    right: 5px;
    padding: 0;

    &:focus {
      font-weight: 400;
    }
  }
}

.alert__content {
  z-index: 1000;
  height: 40px;
  min-width: 200px;
}
