@use 'assets/scss/variables.scss' as variables;

.closeButton {
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 32px;
  height: 32px;
  top: -16px;
  right: -16px;
  background: variables.$btn-close-bg;
  border-radius: 50%;
  border: 2px solid variables.$btn-close-border-color;
  color: variables.$btn-close-color;
  cursor: pointer;
  z-index: 999;
  box-shadow: none;

  &:hover {
    text-decoration: none;
    background-color: rgba(variables.$btn-close-bg, 0.9);
  }

  &:active {
    background-color: rgba(variables.$btn-close-bg, 0.5);
    box-shadow: 7px 6px 28px 1px rgba(variables.$btn-close-color, 0.4);
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    user-select: none;
  }
}
