.ckeditor5__plugin_toolbar_template_action {
  .ck.ck-button {
    gap: 5px;
    font-family: 'Segoe UI' !important;
  }
}
.customvariable__modal {
  .modal-dialog {
    max-width: 800px;
    height: 100%;
    max-height: 550px;
    .modal-content {
      border: none;
      // border-radius: 8px 0px 0px 8px;
      border-radius: 20px;
    }
  }
  &_body {
    width: 100%;
    display: flex;
    padding: 0;
  }
  &_side {
    width: 100%;
    max-width: 160px;
    background: rgb(249, 249, 40);
    background: #2a3042;
    box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0314);
    border-radius: 8px 0px 0px 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    color: white;
    padding: 15px 0px;
    &_item {
      cursor: pointer;
      text-align: center;
      padding: 8px;
      border: none;
      transition: background 0.2s linear;
      &:disabled {
        background: none;
        color: inherit;
        cursor: not-allowed;
      }
      &:hover,
      &.active {
        background: #333b51;
      }
      &.active {
        font-weight: 700;
        color: #34c38f;
      }
    }
  }
  &_tab_wrapper {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0314);
    border-radius: 0px 8px 8px 0px;
    padding: 24px;
  }
  .suite__tab {
    margin: 20px 0px;
    &.active {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }
  }
  .customvariable__modal_tab_suite_title {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #495057;
  }
}
