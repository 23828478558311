.profileMenu {
  color: white;
  cursor: pointer;
  
  &__toggle {
    min-width: 120px;
    display: flex;
    align-items: center;

    &::after {
      border: none;
    }
   
  }
  &__userBox {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: self-end;
    padding-right: 1rem;
  }

  &__menu{
    width: 100%;
  }
}
