.ck-editor__editable {
  min-height: 80px;
}
.ck-toolbar__items {
  flex-wrap: wrap !important;
  .ck.ck-button {
    font-family: 'Roboto' !important;
    &.ck__toolbar_custom_button {
      svg {
        margin-top: 1px;
      }
    }
  }
}
.ck-editor__main {
  .ckeditor5__editable_custom_variable {
    margin: 0px 2px;
  }

  .ckeditor5__editable_custom_action {
    background: white;
    color: black;
    border: 1px solid #34c38f;
    border-radius: 4px;
    padding: 3px 16px;
    display: inline-block;
    font-weight: 500;
  }
}
