.sidebar {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 400;
  width: 450px;
  max-width: 100vw;
  padding: 20px 10px 20px 20px;
  position: fixed;
  // top: 70px;
  // right: 10px;
  right: 0;
  top: 0;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgb(18 38 63 / 3%);
  // border-radius: 4px;
  height: 100vh;

  // transform: scale(0.9);
  h4 {
    padding-left: 5px;
  }

  &__body {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 10px;
    padding-left: 5px;
    display: grid;
    row-gap: 5px;
    // display: flex;
    // overflow-y: auto;
    // height: 100%;
    // justify-content: space-between;
    // width: 99%;
    // align-items: center;
    // flex-direction: column;
  }
}

.test__div {
  width: 200px;
  height: 200px;
  background: red;
  position: absolute;
  left: 0;
  top: 50%;
}
