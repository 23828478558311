.password__input_wrapper {
  position: relative;
  .password__input {
    padding-right: 40px;
  }
  .bx-show {
    position: absolute;
    top: 50%;
    right: 10px;
    font-size: 24px;
    transform: translateY(-50%);
    color: #34c38f;
  }
}
