.card-body {
  border: none;

  &.loading {
    opacity: 0.6;
    pointer-events: none;
  }
}

.card > .card-body {
  box-shadow: 0px 12px 24px 0px #12263f08;
}

.card__fullscreen {
  flex: 1 1 auto;
}

.card__body_full_size {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
}
