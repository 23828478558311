@use 'assets/scss/variables.scss' as variables;

ul.nav-tabs {
  display: inline-flex;
  .nav-item {
    font-family: 'Roboto';
    &:hover,
    .nav-link {
      border: none;
    }
    .nav-link {
      position: relative;
      // border-bottom: 2px solid transparent;
      transition: color 0.2s linear;
      &:after {
        content: '';
        width: 0px;
        height: 2px;
        background: transparent;
        transition: 0.2s ease-in-out;
        position: absolute;
        bottom: 0.5px;
        left: 50%;
      }
      &.active {
        color: variables.$base_color;
        background: none;
        // border-bottom: 2px solid variables.$base_color;
        &:after {
          background: variables.$base_color;
          width: 100%;
          left: 0;
        }
      }
    }
  }
}
