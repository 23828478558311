@use 'assets/scss/variables.scss' as variables;

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(variables.$base_color, 0.3);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: variables.$base_color;
}
