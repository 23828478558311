@use "assets/scss/variables.scss" as variables;

.modalAuth {
  &__header {
    display: flex;
    align-items: baseline;
    height: 120px;
    background-color: #2a3042;
  }

  &__logo {
    height: 40px;
  }

  // .modal-content {

  // }

  .modal-body {
    padding: 1.25rem;
  }

  .modal-dialog.modal-dialog-centered {
    width: 400px;
  }
}
