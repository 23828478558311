.react-flow { 
    &__controls {
        display: flex;
    }

    &__controls-button {
        order: 2;
        
        &--first {
            order: 1;
        }
    }
}