.content {
  &__block {
    &_main {
    display: flex;
    height: 100%;
    height: 100%;
    flex-grow: 1;
    }
    &_page {
      width: 100%;
      padding:56px 12px 12px 12px;// calc(70px + 14px) calc(24px / 2) calc(60px + 40px) calc(24px / 2);
    }
  }

  .container-fluid {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
div#layout-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}