.itemHeader {
  border-top: thick solid;
  border-radius: 7px 7px 0 0;

  &.positive {
    border-color: #309470;
  }
  &.neutral {
    border-color: #e8de28;
  }

  &.negative {
    border-color: #cf6464;
  }

  ::after {
    width: 0px;
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__group {
    margin: 2px 2px 4px 2px;
    button {
      padding: 5px 10px;
    }
  }
}
// button.accordion-button {
//     padding:8px 2px 2px 2px;
// }
