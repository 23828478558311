.brandBox {
  height: 100%;
  display: flex;
  align-items: center;
  //padding: 0 12rem 0 12px;

  &__img {
    height:35px;
  }
}
