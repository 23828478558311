@use 'assets/scss/variables.scss' as variables;


.modal_header {
  display: flex;
  align-items: baseline;
  height: max-content;
  background-color: #2a3042;

  &__logo {
    height: 40px;
  }

  &__text {
    color: variables.$base_color;
    &_level_1 {
      font-size: 1.25rem;
      margin-bottom: 0.25rem !important;
    }
    &_level_2 {
      font-size: variables.$font_size;
      color: #FEFEFE;
    }
  }
}
