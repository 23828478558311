@use 'assets/scss/variables.scss' as variables;

.avatar {
  width: 32px;
  height: 32px;
  background: variables.$base_color;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  text-transform: uppercase;
}
