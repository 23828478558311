.trash__icon {
  cursor: pointer;
  .trash__icon_path {
    fill: #74788d;
    transition: 0.2s fill linear;
  }
  &:hover {
    .trash__icon_path {
      fill: #f46a6a;
    }
  }
}
