@font-face {
  font-family: 'boxicons';
  font-weight: normal;
  font-style: normal;
  src: url('../../../../fonts/boxicons.woff') format('woff');
}

.bx {
  font-family: 'boxicons' !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  display: inline-block;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.bx-ul {
  margin-left: 2em;
  padding-left: 0;

  list-style: none;
}
.bx-ul > li {
  position: relative;
}
.bx-ul .bx {
  font-size: inherit;
  line-height: inherit;

  position: absolute;
  left: -2em;

  width: 2em;

  text-align: center;
}

.bx-exit-fullscreen:before {
  content: '\ea3f';
}
.bx-fullscreen:before {
  content: '\ea5e';
}
.bx-calendar:before {
  content: '\e9c2';
}
.bx-calendar-alt:before {
  content: '\e9c3';
}
.bx-calendar-check:before {
  content: '\e9c4';
}
.bx-calendar-event:before {
  content: '\e9c5';
}
.bx-calendar-minus:before {
  content: '\e9c6';
}
.bx-calendar-plus:before {
  content: '\e9c7';
}
.bx-calendar-x:before {
  content: '\e9c8';
}
.bx-power-off:before {
  content: '\eb03';
}
.bx-code-editor:before {
  content: '\EB6F';
}
.bx-code-tag:before {
  content: '\E9F7';
}
.bx-chevron-down:before {
  content: '\e9e2';
}

.bx-user-circle:before {
  content: '\eb8e';
}

.bx-note:before {
  content: '\eae0';
}

.bx-play-circle:before {
  content: '\eafb';
}

.bx-shield-quarter:before {
  content: '\eb3c';
}

.bx-x:before {
  content: '\ebae';
}

.bx-plug:before {
  content: '\eafc';
}

.bx-check:before {
  content: '\e9da';
}

.bx-dots-horizontal-rounded:before {
  content: '\ea2a';
}

.bx-plus:before {
  content: '\eafd';
}

.bx-minus:before {
  content: '\eace';
}

.bx-task:before {
  content: '\eb6c';
}

.bx-check-shield:before {
  content: '\e9e0';
}

.bx-arrow-back:before {
  content: '\e983';
}

.bx-time-five:before {
  content: '\eb73';
}

.bx-rewind:before {
  content: '\eb19';
}

.bx-play:before {
  content: '\eafa';
}

.bx-pause:before {
  content: '\eaec';
}

.bx-fast-forward:before {
  content: '\ea44';
}

.bx-skip-forward::before {
  content: '\F04AC';
}

.bx-show-alt:before {
  content: '\ef42';
}
.bx-show::before {
  content: '\EB3F';
}

.bx-low-vision:before {
  content: '\eb51';
}

.bx-skip-next:before {
  content: '\eb44';
}

.bx-skip-previous:before {
  content: '\eb46';
}

.bx-trash:before {
  content: '\eb7c';
}

.bx-skip-target:before {
  content: '\eb44';
}
.bx-skip-source:before {
  content: '\eb46';
}

.bx-plus-circle:before {
  content: '\eafe';
}

.bx-minus-circle:before {
  content: '\eacf';
}

.bxs-pencil:before {
  content: '\ece7';
}

.bxs-save::before {
  content: '\ed13';
}

.bx-undo::before {
  content: '\eb83';
}

.bx-redo::before {
  content: '\eb10';
}
.bx-cog::before {
  content: '\e9FC';
}
