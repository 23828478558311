.modalDeleteScript {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 250px;
  color: #adb5bd;

  &__message {
    text-align: center;

    &_level_1 {
      font-size: 1.2rem;
      // margin-bottom: 0.25rem !important;
      color: black;
      max-height: 100px;
      max-width: 100%;
      align-items: baseline;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &_level_2 {
    
    }
  }

  &__controls {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding-top: 1rem;


  }
}
