.controlsTable {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  &__toggle {
    cursor: pointer;
    gap: 0;
    height: 15px;
    &::after {
      border: none;
    }
  }

  &__menu {
    box-shadow: 0px 12px 24px 0px rgb(18 38 63 / 3%);
  }

  &__edit {
    cursor: pointer;
  }
}
