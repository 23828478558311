.modalNoScripts {
  height: 100%;
  text-align: center;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 150px;
    color: #adb5bd;

    &__message {
      text-align: center;
    }
  }
}
