$base_color: #34c38f;
$font_size: 0.9rem;

// --------------  button ---------------

$btn-close-color: #34c38f;
$btn-close-bg: #ffffff;
$btn-close-border-color: #d4daf8;

.btn {
  --bs-btn-font-size: 0.9rem;
}

.btn-close:focus {
  box-shadow: none;
}

.btn-outline-primary {
  --bs-btn-color: #34c38f;
  --bs-btn-bg: #fdfdfd;
  --bs-btn-border-color: #34c38f;
  --bs-btn-hover-bg: #1a7d4edb;
  --bs-btn-hover-border-color: #1a7d4edb;
  --bs-btn-active-bg: #105635db;
  --bs-btn-active-border-color: #1a7d4edb;
  --bs-btn-padding-x: 2rem;
}

.btn-primary {
  --bs-btn-bg: #34c38f;
  --bs-btn-border-color: #34c38f;
  --bs-btn-hover-bg: #1a7d4edb;
  --bs-btn-active-bg: #105635db;
  --bs-btn-active-border-color: #1a7d4edb;
  --bs-btn-hover-border-color: #1a7d4edb;
  --bs-btn-padding-x: 2rem;
}

.btn-primary:disabled,
.btn-primary.disabled {
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #34c38f;
  --bs-btn-disabled-border-color: #34c38f;
}
// --------------  end button ---------------

// --------------  modal ---------------
.modal-backdrop {
  --bs-backdrop-bg: #0000007d;
}

.modal-backdrop.show {
  z-index: 500;
}

// .modal {
//   --bs-modal-border-color: none;

//   height: auto;
// }
// -------------- end modal ---------------

:root {
  --bs-body-bg: #f8f8fb;
  --bs-border-color-translucent: #f8f8fb;
  --bs-body-font-size: 0.9rem;
  --bs-link-color: #34c38f;
  --bs-link-hover-color: #1f986c;
  --ck-focus-ring: 1px solid#ccced1;
}

.pagination {
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 253, 101, 0.25);
  --bs-pagination-font-size: 0.9rem;
}

.dropdown-menu {
  --bs-dropdown-font-size: 0.9rem;
  --bs-dropdown-link-active-bg: #34c38f;
}

// --------------  footer ---------------

$footer-color: #74788d;
$footer-bg: #e8e8ee;

// --------------  end footer ---------------

// --------------  node ---------------

$node-button-add-background: #34c38f;
$node-button-delete-background: #74788d;

$node-button-color: #ffffff;
$node-background: #ffffff;

$node-purpose-background: #92dac2;
// --------------  end footer ---------------

// --------------  nav ---------------

.nav {
  --bs-link-color: $base_color;
  --bs-nav-link-hover-color: #34c38f;
}
// --------------  nav footer ---------------

.form-select:focus {
  border-color: $base_color;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba($base_color, 0.2);
}

.alert {
  --bs-alert-padding-x: 0.5rem;
  --bs-alert-padding-y: 0.5rem;
}

// --------------  react-flow  ---------------
.react-flow {
  /* height: 100ch; */
  overflow: hidden;
  position: relative;
  width: 100%;
}

label {
  font-size: 14px;
  font-weight: 500;
}

.form-control {
  font-size: 14px;
  padding: 0.375rem 0.39rem;
}

.form-control:focus {
  border-color: #34c38f;
  box-shadow: 0 0 0 0.25rem rgb(13 253 101 / 25%);
}

.accordion {
  --bs-accordion-active-color: $base_color;
  --bs-accordion-btn-focus-box-shadow: none;

  --bs-accordion-active-bg: #d4efe5;
  --bs-accordion-btn-padding-x: 12px;
  --bs-accordion-btn-padding-y: 2px;
}

.form-check-input:checked {
  background-color: #34c38f;
  border-color: #34c38f;
}

.form-check-input:checked[type='radio'] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E");
}
.form-check-input:focus {
  border-color: #34c38f;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(#34c38f, 25%);
}
