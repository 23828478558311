.separator {
  margin: 1rem 0;
  padding-bottom: 1rem;
  position: relative;
  text-align: center;
  
  span {
    position: absolute;
    color: #a3a8b1;
    left: 2%;
    background-color: #fff;
    padding: 0 0.5rem;
  }

  &::before {
    background-color: #dcdfe4;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    top: 10px;
    width: 100%;
  }

}


