@use 'assets/scss/variables.scss' as variables;

.breadcrumbs {
  margin: 12px 0;

  .breadcrumbs__title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px !important;
  }

  .breadcrumbs__item a,
  span {
    text-decoration: none;
    color: variables.$base_color;
  }
}
