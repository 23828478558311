.formCreateUser {
  display: grid;
  grid-row-gap: 0.5rem;

  &__item {
    label {
      font-weight: 500;
    }
  }
  &__controls {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    padding: 0.4rem;
    white-space: nowrap;
    
    &[type='reset'] {
      width: 130px;
    }

    &[type='submit'] {
      margin-left: 1rem;
      width: 130px;
    }

    &_password {
      width: 100%;
    }
  }
}
