@use 'assets/scss/variables.scss' as variables;

.node {
  width: 180px;
  min-height: 62px;
  background: variables.$node-background;
  box-shadow: 0px 10px 20px rgba(variables.$base_color, 0.2);
//  box-shadow: 0px 10px 20px rgba(rgba(128, 128, 128, 0.255), 0.2);
  border-radius: 3px;

  padding: 7px 11px 10px 15px;
  border: 1px solid rgba(0, 0, 0, 0.198);
  transition: all 0.7s ease;

  &__button {
    cursor: pointer;
    padding: 0;
    border: none;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: variables.$node-button-color;
    pointer-events: all;
    
    &_remove {
      background: variables.$node-button-delete-background;
    }

    &_add {
      background: variables.$node-button-add-background;
      margin-right: 5px;
    }

    &__wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }

  &_purpose {
    background: variables.$node-purpose-background;
  }
}

.node:hover {
  border: 1px solid 
  // background: #34c38fde;

  // background: radial-gradient(
  //   circle at 18.7% 37.8%,
  //   rgb(252, 252, 252) 0%,
  //   rgb(239, 241, 243) 90%
  // );
}

.selected .node {
  border: 5px solid #8ac79f;
  z-index: 20;
}


.react-flow__node  {
  z-index: -2px !important;
}

.react-flow__node-node {
  z-index: -2px !important;
}