.toolbar {
  flex-grow: 1;
  height: 100%;
  max-width: 75%;
  display: flex;

  &__logo {
    padding-left: 1rem;
    flex-grow: 1;
    display: none; //<- for demo
  }

  &__content {
    flex-grow: 5;
    display: flex;
     padding: 1rem; //<- for demo
    justify-content: flex-start;
    align-items: center;
  }

  &__item {
    color: white;
    padding: 1rem;

    &.active {
      a {
        transition: none !important;
        color: #ffffff;
      }
    }
    a {
      text-decoration: none;
      color: rgba(255, 255, 255, 0.635);
      display: flex;
      align-items: center;
      transition: all 0.4s;
      &:hover {
        color: #ffffff;
      }
    }

    &_text {
      font-size: 14px;
    }

    &_icon {
      font-size: 20px;
      padding-right: 0.2rem;
    }
  }
}

@media (max-width: 990px) {
  span.toolbar__item_text {
    display: none;
  }
}

// @media (max-width: 778px) {
//   .menu-title {
//     display: none;
//   }
// }
